@import url('https://fonts.googleapis.com/css2?family=Jura:wght@600&display=swap');

html,body {
  overflow-x: hidden;
}
.instagram, .twitter, .vk, .telegram, .tiktok, .facebook,.youtube,.email,.map, .web, .phone,.playmarket,.appstore,.viber,.whatsapp,.field-button {
  color: white;
  padding: 10px 40px;
  border-radius: 20px;
  font-size: 20px;
  word-wrap: break-word;
      @media all and (max-width: 500px) {
      padding: 10px 10px 10px 15px;
      border-radius: 20px;
      font-size: 20px;
    }

  div:first-child {
    font-weight: bold;
  }
  &:hover {
    color: #ded3d3;
    text-decoration: none;
  }
}
.form-control {
  font-size: 15px;
}
.icons {
  // svg {
  //   path {
  //     fill: blue;
  //   }
  // }
  .block-svg {
    align-self: center;
    height: 40px;
  }
}
.whatsapp {
  background-color: #25d366;
  div:first-child {
    font-weight: bold;
  }
  &:hover {
    color: #ded3d3;
    text-decoration: none;
  }
}
.viber {
  background-color: #7360f2;
  div:first-child {
    font-weight: bold;
  }
  &:hover {
    color: #ded3d3;
    text-decoration: none;
  }
}
.instagram {
  background-color: #bf0662;
  div:first-child {
    font-weight: bold;
  }
  &:hover {
    color: #ded3d3;
    text-decoration: none;
  }
}
.twitter {
  background-color: #1da1f2;
  div:first-child {
    font-weight: bold;
  }
  &:hover {
    color: #ded3d3;
    text-decoration: none;
  }
}
.vk {
  background-color: #2787f5;
  div:first-child {
    font-weight: bold;
  }
  &:hover {
    color: #ded3d3;
    text-decoration: none;
  }
}
.telegram {
  background-color: #31a8e0;
}
.tiktok {
  background-color: black;
  .tiktok-svg {
    background-color: #fff;
  }
}
.facebook {
  background-color: #4867aa;
}
.youtube {
  background-color: red;
}
.email {
  background-color: #5f2ed2;
}
.map {
  background-color: #4cd950;
}
.web {
  background-color: #e54c04;
}
.appstore {
  background-color: #1c98f8;
}
.playmarket {
  background-color: #01aa53;
}
.phone {
  background-color: green;
}
.element-text {
  width: 75%;
  padding: 0 10px 0 15px;

}
.text {
  overflow-wrap: break-word;
  padding: 10px 0;
  pointer-events: none;
  cursor: default;
  color: black;
  font-size: 20px;
}
.burger {
  position: relative;
}
.fix {
  position: relative;

  .fix_button {
    height: 30px;
    width: 30px;
    padding: 0;
    background-color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    .button_svg {
      img {
        background: transparent;
        height: 35px;
      }
    }
  }
}
.element-photo img {
  margin-top: 30px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: black;
  margin-bottom: 30px;
  object-fit: cover;
}
#uplodfile {
  display: none;
}
h2 {
  input {
    text-align: center;
    border: 0;
  }
}
.btn-change-color {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0069d9;
  max-width: 220px;
  border: none;
  margin-top: 20px;
  margin-bottom: 20px;
  color: white;
  text-align: center;
  border: 1px solid white;
  &:disabled {
    background-color: transparent;
    color: #0069d9;
    border: 1px solid black;
  }
  &:hover:enabled {
    background-color: #1c98f8;
  }
}
.btn-primary {
  background-color: #fff;
  width: 100%;
  padding: 10px 40px;
  border-radius: 20px;
  border: 1px solid black;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: black;
  text-align: center;
  height: 50px;
  div {
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:before, &:after {
      position: absolute;
      left: 50%;
      content: ' ';
      height: 33px;
      width: 2px;
      background-color: #333;
    }
    &:before {
      transform: rotate(90deg);
    }
    &:after {
      transform: rotate(0deg);
    }

  }
  &.danger {
    &:hover {
      background-color: rgba(75, 1, 27, 0.54);
    }
  }
  &:hover {
    div {
      &:before {
        background: white;
      }
      &:after {
        background: white;
      }
    }
  }
}
.line-1 {
  cursor: default;
  width: 100%;
  height: 30px;
  margin-bottom: 30px;
  border-bottom: 2px dashed black;
  svg{
    top: 100%;
    transform: translateY(-50%);
  }
}
.line-2 {
  cursor: default;
  width: 100%;
  height: 30px;
  margin-bottom: 30px;
  border-bottom: 2px solid black;

  svg{
    top: 100%;
    transform: translateY(-50%);
  }
}
.line-3 {
  cursor: default;
  display: block;
  position: relative;
  &:before {
    z-index: 100;
    position: absolute;
    content: '\25C8';
    color: black;
    font-size: 30px;
    background-color: white;
    border-radius: 50%;
    width: 10%;
    text-align: center;
    transform: translateX(-50%) translateY(-10px);
    top: 50%;
    left: 50%;
  }
  width: 100%;
  height: 30px;
  margin-bottom: 30px;
  border-bottom: 2px solid black;
  svg{
    top: 100%;
    transform: translateY(-50%);
  }
}
.line-4 {
  cursor: default;
  display: block;
  position: relative;
  &:before {
    position: absolute;
    content: '\221E';
    color: black;
    font-size: 30px;
    background-color: white;
    border-radius: 50%;
    width: 10%;
    text-align: center;
    transform: translateX(-50%) translateY(-10px);
    top: 50%;
    left: 50%;
  }
  width: 100%;
  height: 30px;
  margin-bottom: 30px;
  border-bottom: 2px solid black;

  svg{
    top: 100%;
    transform: translateY(-50%);
  }
}
.line-1, .line-2, .line-3, .line-4 {
  .fix_button{
    top:100%;
    transform: none;
  }
  .burger_svg {
    top: 100%;
  }
}

input {
  margin-bottom: 10px;
  width: 100%;
  padding: 5px;
  border: 1px solid rgba(0,0,0,0.5);
  border-radius: 10px;
}
.form-check-input {
  position: static;
  margin: 0;
}
.btn-secondary {
  border-radius: 0;
  flex-shrink: 0;
  border: none;
  width: max-content;
}
.btn-group:not(:first-child), .btn-group>.btn:not(:first-child) {
  margin-left: 0;
}
.btn:focus {
  box-shadow: none;
}

.Title {
  font-family: 'Jura', sans-serif;
  position: relative;
  margin-bottom: 10px;
  padding: 5px;
  text-align: center;
  word-wrap: break-word;
  input {
    border: none;
    padding: 0;
    background-color: transparent;
    word-wrap: break-word;
    border-radius: 10px;
    width: 100%;
    margin-bottom: 0;
  }
  .nickname-button {
    border: none;
    outline: none;
    color: black;
    padding: 0;
    background-color: transparent;
    word-wrap: break-word;
    border-radius: 10px;
    width: 100%;
    margin-bottom: 0;
  }
}
.submit {
  position: absolute;
  text-align: center;
  padding: 0;
  right: -60px;
  width: 40px;
  height: 40px;
  visibility: collapse;
}
.visibility {
  visibility: visible;
}
.bg-login {
  background: linear-gradient(rgba(0, 94, 255, 0.5), rgba(69, 8, 212, 0.5));
  font-family: 'Jura', sans-serif;
}
.TOUCH-title {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 10%;
  font-size: 30px;
  font-family: 'Jura', sans-serif;
}
.card {
  border: none;

  .btn-danger {
    border-radius: 50px;
    width: 60%;
    padding: 10px 10px;
    text-transform: uppercase;
  }
}
.card-title {
  font-size: 100px;
  margin-bottom: 50px;
}
.block_login {
  border: 3px solid rgba(0, 0, 0, 0.65);
  position: absolute;
  right: 0;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.65);
  background-color: white;
  transform: translateX(32%);
  overflow: hidden;
}
.block_show {
  border: none;
  background-color: transparent;
  border: 3px solid rgba(0, 0, 0, 0.65);
  position: absolute;
  right: 48px;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.65);
  background-color: white;
  transform: translateX(32%);
  overflow: hidden;
}
.input-login {
  position: relative;
  img {
    height: 30px;
    transform: translateY(5px);
  }
}
.input-pas {
  position: relative;
  img {
    transform: none;
    height: 20px;
  }
}
.input-group>.form-control:focus {
  z-index: 0;
  box-shadow: none;

}
.input-color {
  margin-top: 5px;
  input {
    width: 30px;
    height: 30px;
    border: none;
    background: white;
    outline: none;
    padding: 0;
    margin: 0 5px 0 0;
    opacity: 0.8;
  }
}
.user-info {
  position: relative;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  .preview {
    border: none;
    position: absolute;
    left: 80%;
    transform: translateX(-50%) translateY(-50%);
    top: 30%;
    background-color: transparent;
    img {
      height: 40px;
    }
    &.qrpreview {
      left: 20%;
    }
  }
  .modal-body {
    img {
      width: 100%;
    }
    p {
      margin: 0;
      padding: 0;
    }
  }
}
.modal-login {
  &-header {
    font-weight: 500;
    text-align: center;
  }
  &-text {
    text-align: center;
  }
}
.main {
  background-color: white;
  padding-bottom: 20px;
  padding-top: 20px;
  margin-bottom: 20px;
}
#logModal {
  .modal-footer {
    &_row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 32px;
      button {
        margin: 0 auto;
        max-width: 200px;
        &:not(:first-child) {
          margin-left: 12px;
        }
      }
    }
    .btn-primary {
      font-size: 17px;
      height: auto;
    }
  }
}
@media (max-width: 350px) {
  .card-title {
    font-size: 60px;
    margin-bottom: 30px;
  }
  .card .btn-danger {
    width: 80%;
  }

}
@media (max-width: 500px) {
  .card-title {
    font-size: 60px;
    margin-bottom: 30px;
  }
  .card .btn-danger {
    width: 80%;
  }
  .instagram, .twitter, .vk, .telegram, .tiktok, .facebook,.youtube,.email,.map, .web, .phone,.playmarket,.appstore,.viber,.whatsapp {
    padding: 10px 10px 10px 15px;
    border-radius: 20px;
    font-size: 20px;
    // margin-top: 10px;
    // margin-bottom: 10px;

  }
  .element-photo img{
    height: 150px;
    width: 150px;
  }
  .element-text {
    font-size: 15px;
    padding: 0 10px 0 15px;
    border-radius: 20px;
  }
  .user-info {
    border-bottom-left-radius: 40%;
    border-bottom-right-radius:40%;
  }
}
.btn-save-changes {
  width: 100%;
  border-radius: 20px;
  font-size: 20px;
  text-align: center;
  height: 50px;
}
.colors-container {
  display: flex;
  flex-direction: column;
  max-width: 220px;
  margin-top: 16px;
  margin-bottom: 32px;
  &-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  input {
    width: 30px;
    height: 30px;
    border: none;
    background: white;
    outline: none;
    padding: 0;
    opacity: 0.8;
    margin-left: 12px;
    &:first-child {
      margin-right: 20px;
    }
  }
}
.mce-notification.mce-in, .tox-notifications-container {
  display: none;
}

.canvas_avatar {
  max-width: 100%;
}

.text_field {
  padding: 12px;
  border-radius: 20px;
}
.login-modal-one {
  width: 90%;
  margin: 12px auto 0;
  .modal-content {
    padding: 14px;
  }
}
.text-field-button {
  margin: 0;
  padding: 20px;
  p {
    font-weight: normal;
    margin: 0;
  }
}
.color-input-field {
  border: 1px solid LightSlateGray;
  border-style: solid;
  &::-webkit-color-swatch {
	  border: 1px solid LightSlateGray;
    border-style: solid;
    border-radius: 0px;
  }
}
.fields-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  >div:not(:last-child) {
    margin-bottom: 40px;
    @media all and (max-width: 568px) {
      margin-bottom: 20px;
    }
  }
  @media all and (max-width: 568px) {
    margin-bottom: 10px;
  }
}
.text-button-container {
  width: 100%;
}
.controls-container {
  display: flex;
  flex-direction: column;
}
.vcard-button {
  border:none;
  margin: 0 auto;
  background-color: #3D8BEA;
  color: white;
  margin-top: 14px;
  border-radius: 4px;
  padding: 6px 16px;
  font-weight: 500;
  transition: all ease-in-out .1s;
  &:active {
    transform: scale(0.8);
  }
}